<template>
  <div class="reservation">
      <div class="header">
        <!-- <el-popconfirm
          title="确定要通过这些商品吗？"
          @confirm="handleBatchPass"
          style="margin-left: 10px;" 
        >
      </el-popconfirm> -->
        <el-button class="btn" @click="handleBatchPass" type="primary">通过</el-button>
        <el-button class="btn" @click="handleRefuse" type="danger">不通过</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" @selection-change="handleSelectionChange" row-key="id">
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="55">
        </el-table-column>
        <el-table-column prop="shopName" label="所属店铺" width="150">
        </el-table-column>
        <el-table-column prop="name" label="商品名称" width="200">
        </el-table-column>
        <el-table-column prop="goodsTypeName" label="商品类别" width="200">
        </el-table-column>
        <el-table-column prop="saleAmount" label="售价" width="100">
        </el-table-column>
        <el-table-column prop="inventoryCount" label="库存" width="100">
        </el-table-column>
        <el-table-column prop="calculateSaleCount" label="已售数量" width="100">
        </el-table-column>
        <el-table-column prop="property" label="商品属性" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.property == 1 ? '服务类' : '实物类' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="scopes" label="对谁可见" width="220">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.scopes"  :key="index">
              <span v-if="item == 1">企业采购商、</span>
              <span v-if="item == 2">企业个人、</span>
              <span v-if="item == 3">顾客 </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="{ row }">
            {{  row.status == 0 ? '新增' : 
                row.status == 1 ? '待审核' : 
                row.status == 2 ? '审核未通过' : 
                row.status == 3 ? '上架' : 
                row.status == 4 ? '下架' : 
                row.status == 5 ? '删除' : 
                '--'  
            }}
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" width="180" fixed="right">
          <template slot-scope="{row}">
            <el-button type="text" @click="handleDetail(row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <d-paging 
        :pager="pager"
        @change="getGoodsList">
      </d-paging>
      <el-dialog title="添加原因" :visible.sync="dialogVisible" width="400">
          <el-form ref="refuseForm" :rules="refuseRules" :model="refuseForm" label-width="80px">
              <el-form-item label="原因：" prop="reason">
                <el-input v-model="refuseForm.reason" placeholder="输入不通过原因"></el-input>
              </el-form-item>
          </el-form>
            
          <div slot="footer">
              <el-button @click="dialogVisible = false, refuseForm.reason = ''">取 消</el-button>
              <el-button type="primary" @click="handleBatchRefuse">确 定</el-button>
          </div>
      </el-dialog>
  </div>
</template>
<script>
import DPaging from '@/components/d-paging'
export default {
  name: 'ShopManage',
  components: {
    DPaging
  },
  data () {
    return {
      tableData: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      selectRows: [], // 已选择的行
      dialogVisible: false,
      refuseForm: {
        reason: ''
      },
      refuseRules: {
        reason: [
          { required: true, message: '请输入不通过原因', trigger: 'blur' },
        ]
      },
      userInfo: {}
    }
  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getGoodsList()
  },
  methods: {
    // 商品列表
    getGoodsList () {
      const postData = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        statusList: [1] + '',
        shopId: this.userInfo.type == 3 ? this.userInfo.shopId : '',
      }
      this.$api.shopManageApi.businessGoodsList(postData).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.list
          this.pager.count = res.data.total
        } 
      })
    },
    handleRefuse () {
      if (this.selectRows.length == 0) {
        this.$message.warning('请选择商品')
        return
      }
      this.dialogVisible = true
    },
    // 批量通过
    handleBatchPass () {
      if (this.selectRows.length == 0) {
        this.$message.warning('请选择商品')
        return
      }
      let selectRowIds = []
      this.selectRows.forEach(item => {
        selectRowIds.push({id: item.id})
      })
      this.$api.shopManageApi.goodsPass(selectRowIds).then(res => {
        if (res.code == 200) {
          this.$message.success('通过成功')
          this.getGoodsList()
        } else {
          this.$message.success('通过失败：' + res.msg)
        }
      })
    },
    // 批量驳回
    handleBatchRefuse () {
      this.$refs.refuseForm.validate((valid) => {
        if (valid) {
          let selectRowIds = []
          this.selectRows.forEach(item => {
            selectRowIds.push({id: item.id, reason: this.refuseForm.reason})
          })
          this.$api.shopManageApi.goodsRefuse(selectRowIds).then(res => {
            if (res.code == 200) {
              this.$message.success('驳回成功')
              this.refuseForm.reason = ''
              this.dialogVisible = false
              this.getGoodsList()
            } else {
              this.$message.success('驳回失败：' + res.msg)
            }
          })
        }
      })
    },
    // 多选
    handleSelectionChange (val) {
      this.selectRows = val
    },
    // 去详情
    handleDetail (id) {
      this.$router.push(`/shop-mall/shop-manage/shop-manage/detail/${id}`)
    },
  }
}
</script>
<style lang="scss" scoped>
.reservation {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  .header{
    width: 100%;
    display: flex;
    height: 60px;
    align-items: center;
    padding: 0 20px;
    background-color: #fff;
    margin-bottom: 10px;
    .btn{
      height: 30px;
    }
  }
}
</style>
